import {Language} from './Language';

/** Utility methods for handling languages. At the moment, this only has limited visibility. 
 *  More Methods could be made public in the future if we really need those methods. */

export class KnownLanguages {

	/** Displays the language in the displayLanguage (if translations for displayLanguage
	 * are available, otherwise falls back to english). */
	static display(language: Language, displayLanguage: Language): string {
		var idx: number = (Language.equalPrimaries(Language.DE, displayLanguage) ? KnownLanguages.INDEX_DE : KnownLanguages.INDEX_EN ); 
		if (language.hasRegion()) {
			return KnownLanguages.getLanguageName(language.getPrimary(),idx) + " (" + KnownLanguages.getRegionNameByIndex(language.getRegion(),idx) + ")";
		} else {
			return KnownLanguages.getLanguageName(language.getPrimary(),idx); 
		}
	}
	
	/** 
	 * Fetches the display name of the region in the display language (if the display language is not supported, 
	 * will return the english display name of the region). 
	 * @return The display name of the region in the displayLanguage or null  if not found. */	
	  static getRegionName(region: string, displayLanguage: Language = Language.EN): string | null {
		var offset: number = (Language.equalPrimaries(Language.DE, displayLanguage) ? KnownLanguages.INDEX_DE : KnownLanguages.INDEX_EN );
		KnownLanguages.buildIndices();
		var idx:number|undefined = KnownLanguages.indices.get(region);
		return idx == undefined ? null : KnownLanguages.regions[idx+offset]!;
	}
     
    
	/** Does not check whether this language already has a region (as this is intended to be called 
	 * from {@link Language}. */
	static expandLanguage(lang: Language): Language | null {
		KnownLanguages.buildIndices();
		var idx:number|undefined = KnownLanguages.indices.get(lang.getPrimary());

		return ( idx == undefined ? null : Language.from(KnownLanguages.languages[idx+KnownLanguages.LANGUAGE_PARAM_COUNT-1]!) );
	}


	private static getLanguageName(isocode: string, index: number): string {	
		KnownLanguages.buildIndices();
		var idx:number|undefined = KnownLanguages.indices.get(isocode);
		return idx == undefined ? "-(unknown)-" : KnownLanguages.languages[idx+index]!;
	}

	private static getRegionNameByIndex(isocode: string, index: number): string {
		KnownLanguages.buildIndices();
		var idx:number|undefined = KnownLanguages.indices.get(isocode);
		return idx == undefined ? "-(unknown)-" : KnownLanguages.regions[idx+index]!;
	}
	
	/** Builds the indices needed for accessing language or region data. */
	private static buildIndices(): void {
		if (KnownLanguages.indices == undefined) {
			KnownLanguages.indices = new Map();
			for (var i:number=0;i<KnownLanguages.languages.length;i+=KnownLanguages.LANGUAGE_PARAM_COUNT) {
				KnownLanguages.indices.set(KnownLanguages.languages[i]!, i);
			}
			for (var i:number=0;i<KnownLanguages.regions.length;i+=KnownLanguages.REGION_PARAM_COUNT) {
				KnownLanguages.indices.set(KnownLanguages.regions[i]!, i);				 
			}
		}
	}
	
	/** Contains the indices to languages and regions in the associated arrays. */
	private static indices: Map<string,number>; 

	private static INDEX_EN: number = 1;
	private static INDEX_DE: number = 2; 

	private static LANGUAGE_PARAM_COUNT: number = 4; 	
	/** List of languages their English and German display names followed by the default language-region combination. Primary languages subtags are included 
	 *  in this array even though they could be derived from the full language codes easily (but because they are keys 
	 *  of the languages HashMap, strings will be created for them anyhow. */
	private static languages: string[] = [
		// - - - - - - - START OF COPY FROM KnownLanguages.java - - - - - - - - - - - - - 
			"af","Afrikaans","Afrikaans","af-ZA",
			"ar","Arabic","Arabisch","ar-EG",
			"az","Azeri","Aserbeidschanisch","az-AZ",
			"be","Belarusian","Weißrussisch","be-BY",
			"bg","Bulgarian","Bulgarisch","bg-BG",
			"bn","Bangla","Bengalisch","bn-BD",
			"bs","Bosnian","Bosnisch","bs-BA",
			"ca","Catalan","Katalanisch","ca-ES",
			"cmn","Mandarin","Mandarin","cmn-CN",
			"cs","Czech","Tschechisch","cs-CZ",
			"cy","Welsh","Walisisch","cy-GB",
			"da","Danish","Dänisch","da-DK",
			"de","German","Deutsch","de-DE",
			"dv","Divehi","Maledivisch","dv-MV",
			"el","Greek","Griechisch","el-GR",
			"en","English","Englisch","en-US",
			"eo", "Esperanto","Esperanto","eo-XA", //we might also use eo-US
			"es","Spanish","Spanisch","es-ES",
			"et","Estonian","Estnisch","et-EE",
			"eu","Basque","Baskisch","eu-ES",
			"fa","Farsi","Persisch","fa-IR",
			"fi","Finnish","Finnisch","fi-FI",
			"fil","Filipino","Filipino","fil-PH",
			"fo","Faroese","Färöisch","fo-FO",
			"fr","French","Französisch","fr-FR",
			"gl","Galician","Galicisch","gl-ES",
			"gu","Gujarati","Gujarati","gu-IN",
			"he","Hebrew","Hebräisch","he-IL",
			"hi","Hindi","Hindi","hi-IN",
			"hr","Croatian","Kroatisch","hr-HR",
			"hu","Hungarian","Ungarisch","hu-HU",
			"hy","Armenian","Armenisch","hy-AM",
			"id","Indonesian","Indonesisch","id-ID",
			"is","Icelandic","Isländisch","is-IS",
			"it","Italian","Italienisch","it-IT",
			"ja","Japanese","Japanisch","ja-JP",
			"ka","Georgian","Georgisch","ka-GE",
			"kk","Kazakh","Kasachisch","kk-KZ",
			"kn","Kannada","Kannada","kn-IN",
			"ko","Korean","Koreanisch","ko-KR",
			"kok","Konkani","Konkani","kok-IN",
			"ky","Kyrgyz","Kirgisisch","ky-KG",
			"lt","Lithuanian","Litauisch","lt-LT",
			"lv","Latvian","Lettisch","lv-LV",
			"mi","Maori","Maori","mi-NZ",
			"mk","Macedonian","Makedonisch","mk-MK",
			"ml","Malayalam","Malayalam","ml-IN",
			"mn","Mongolian","Mongoloisch","mn-MN",
			"mr","Marathi","Marathi","mr-IN",
			"ms","Malay","Malaiisch","ms-MY",
			"mt","Maltese","Maltesisch","mt-MT",
			"nan","Southern Min","Minnan","nan-CN",
			"nb","Norwegian (Bokmal)","Norwegisch (Bokmål)","nb-NO",
			"nl","Dutch","Niederländisch","nl-NL",
			"nn","Norwegian (Nynorsk)","Norwegisch (Nynorsk)","nn-NO",
			"no","Norwegian","Norwegisch","no-NO",
			"ns","Northern Sotho","Pedi","ns-ZA",
			"pa","Punjabi","Punjabi","pa-IN",
			"pl","Polish","Polnisch","pl-PL",
			"ps","Pashto","Pashtu","ps-AR",
			"pt","Portuguese","Portugiesisch","pt-PT",
			"qu","Quechua","Quechua","qu-PE",
			"ro","Romanian","Rumänisch","ro-RO",
			"ru","Russian","Russisch","ru-RU",
			"sa","Sanskrit","Sanskrit","sa-IN",
			"se","Sami","Samisch","se-FI",
			"sk","Slovak","Slowakisch","sk-SK",
			"sl","Slovenian","Slovenisch","sl-SI",
			"sr","Serbian","Serbisch","sr-SP",
			"sv","Swedish","Schwedisch","sv-SE",
			"sw","Swahili","Swahili","sw-KE",
			"syr","Syriac","Syrisch","syr-SY",
			"ta","Tamil","Tamilisch","ta-IN",
			"te","Telugu","Telugu","te-IN",
			"th","Thai","Thailändisch","th-TH",
			"tl","Tagalog","Tagalog","tl-PH",
			"tn","Tswana","Tswana","tn-ZA",
			"tr","Turkish","Türkisch","tr-TR",
			"tt","Tatar","Tatarisch","tt-RU",
			"uk","Ukrainian","Ukrainisch","uk-UA",
			"ur","Urdu","Urdu","ur-PK",
			"uz","Uzbek","Usbekisch","uz-UZ",
			"vi","Vietnamese","Vietnamesisch","vi-VN",
			"xh","Xhosa","Xhosa","xh-ZA",
			"yue","Cantonese","Kantonesisch","yue-CN",
			"zh","Chinese","Chinesisch","zh-CN",
			"zu","Zulu","Zulu","zu-ZA",
			// - - - - - - - END OF COPY FROM KnownLanguages.java - - - - - - - - - - - - -			
	];

	private static  REGION_PARAM_COUNT: number = 3;  
	/** List of regions with abbreviation and display name in English and German.  */	
	private static regions: string[] = [
		    // - - - - - - - START OF COPY FROM KnownLanguages.java - - - - - - - - - - - - -
			"AM","Armenia","Armenien",
			"AR","Afghanistan","Afghanistan",
			"AU","Australia","Australien",
			"AZ","Azerbaijan","Aserbaidschan",
			"BA","Bosnia and Herzegovina","",
			"BE","Belgium","Belgien",
			"BD","Bangladesh","Bangladesch",
			"BG","Bulgaria","Bulgarien",
			"BR","Brazil","Brasilien",
			"BY","Belarus","Belarus",
			"CA","Canada","Kanada",
			"CN","China","China",
			"CZ","Czech Republic","Tschechien",
			"DE","Germany","Deutschland",
			"DK","Denmark","Dänemark",
			"EE","Estonia","Estland",
			"EG","Egypt","Ägypten",
			"ES","Spain","Spanien",
			"FI","Finland","Finnland",
			"FO","Faroe Islands","",
			"FR","France","Frankreich",
			"GB","United Kingdom","Vereinigtes Königreich",
			"GE","Georgia","Georgien",
			"GR","Greece","Griechenland",
			"HK","Hong Kong","Hongkong",
			"HR","Croatia","Kroatien",
			"HU","Hungary","Ungarn",
			"ID","Indonesia","Indonesien",
			"IL","Israel","Israel",
			"IN","India","Indien",
			"IR","Iran","Iran",
			"IS","Iceland","Island",
			"IT","Italy","Italien",
			"JP","Japan","Japan",
			"KE","Kenya","Kenia",
			"KG","Kyrgyzstan","Kirgisistan",
			"KR","Korea","Südkorea",
			"KZ","Kazakhstan","Kasachstan",
			"LT","Lithuania","Litauen",
			"LV","Latvia","Lettland",
			"MK","Macedonia","Makedonien",
			"MN","Mongolia","Mongolei",
			"MT","Malta","Malta",
			"MV","Maldives","Malediven",
			"MY","Malaysia","Malaysia",
			"NL","Netherlands","Niederlande",
			"NO","Norway","Norwegen",
			"NZ","New Zealand","Neuseeland",
			"PE","Peru","Peru",
			"PH","Philippines","Philippinen",
			"PK","Pakistan","Pakistan",
			"PL","Poland","Polen",
			"PT","Portugal","Portugal",
			"RO","Romania","Rumänien",
			"RS","Serbia","Serbien",
			"RU","Russia","Russland",
			"SE","Sweden","Schweden",
			"SI","Slovenia","Slovenien",
			"SK","Slovakia","Slowakei",
			"SP","Serbia and Montenegro","Serbien und Montenegro",
			"SY","Syria","Syrien",
			"TH","Thailand","Thailand",
			"TR","Turkey","Türkei",
			"TW","Taiwan","Taiwan",
			"UA","Ukraine","Ukraine",
			"US","United States","Vereinigte Staaten",
			"UZ","Uzbekistan","Usbekistan",
			"VN","Viet Nam","Vietnam",
			"XA","unknown region","unbekannte Region",
			"ZA","South Africa","Südafrika",
		// - - - - - - - END OF COPY FROM KnownLanguages.java - - - - - - - - - - - - -			
	];
	
	
}
