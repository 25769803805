<template>
  <footer :class="{ playerVisible }">
    <div>
      <img id="footerLogo" width="150" height="53" :src="problindLogo" alt="problind Logo" />
      <nav :aria-label="$t('aria_footer_nav')">
        <ul>
          <li><a :href="$t('problind_url_contact')">
            {{ $t("problind_contact") }}
            <span
              class="pi pi-external-link"
              :aria-label="$t('postfix_extern')"
            />
          </a></li>
          <li><a :href="$t('problind_url_faq')">
            {{ $t("problind_faq") }}
            <span
              class="pi pi-external-link"
              :aria-label="$t('postfix_extern')"
            />
          </a></li>
          <li><a :href="$t('problind_url_imprint')">
            {{ $t("problind_imprint") }}
            <span
              class="pi pi-external-link"
              :aria-label="$t('postfix_extern')"
            />
          </a></li>
          <li><a :href="$t('problind_url_privacy')">
            {{ $t("problind_privacy") }}
            <span
              class="pi pi-external-link"
              :aria-label="$t('postfix_extern')"
            />
          </a></li>
          <li>
            <button 
              class="linkLike" 
              @click="switchLanguage"
            >
              {{ $t("problind_other_language") }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import problindLogo from "@/assets/problind.svg";

interface FooterData {
  problindLogo: typeof problindLogo;
}

export default defineComponent({
  name: "Footer",
  data(): FooterData {
    return {
      problindLogo,
    };
  },
  props: {
    playerVisible: Boolean,
  },
  methods: {
    switchLanguage(): void {
     this.$root.switchUiLanguage();
    },
  },
});
</script>
<style scoped>
footer {
  background-color: #18203A;
  color: white;
  display: flex;
  gap: 300px;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

footer.playerVisible {
  padding-bottom: 93px;
}

footer>div {
  max-width: 1100px;
  display: flex;
  flex-grow: 1;
  margin-left:12px;
  margin-right: 12px;
  justify-content: space-between;
}

nav {
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding-right: 200px;
}

@media (max-width: 800px) {
  nav {
    padding-right: 50px;
  }
}

nav li {
  list-style-type: "›";
  padding-left: 10px;
  font-size: 10pt;
}

button.linkLike {
  all: unset;
  cursor: pointer;
}

nav a,
nav button.linkLike
 {
  color: white;
  text-decoration: none;
  font-weight: 100;
}

nav a[aria-current="page"] {
  font-weight: 800;
}

nav a:hover, 
nav a:focus,
nav button.linkLike:hover, 
nav button.linkLike:focus {
  text-decoration: underline;
}

#footerLogo {
  /* This allows us to use the same logo graphic as in the header */
  filter: contrast(0%) brightness(2);
}

span.pi {
  margin-left: 5px;
  font-size: 0.7rem;
}

</style>